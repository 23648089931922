/*Fonts*/
@font-face {
    font-family: 'Inter-Light';
    src: url('../fonts/Inter-Light.eot');
    src: url('../fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Inter-Light.woff2') format('woff2'),
         url('../fonts/Inter-Light.woff') format('woff'),
         url('../fonts/Inter-Light.ttf')  format('truetype'),
         url('../fonts/Inter-Light.svg#Inter Light') format('svg');
  }
  @font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.eot');
    src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Inter-Regular.woff2') format('woff2'),
         url('../fonts/Inter-Regular.woff') format('woff'),
         url('../fonts/Inter-Regular.ttf')  format('truetype'),
         url('../fonts/Inter-Regular.svg#Inter') format('svg');
  }
  @font-face {
    font-family: 'Inter-Medium';
    src: url('../fonts/Inter-Medium.eot');
    src: url('../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Inter-Medium.woff2') format('woff2'),
         url('../fonts/Inter-Medium.woff') format('woff'),
         url('../fonts/Inter-Medium.ttf')  format('truetype'),
         url('../fonts/Inter-Medium.svg#Inter Medium') format('svg');
  }
  @font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.eot');
    src: url('../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Inter-Bold.woff2') format('woff2'),
         url('../fonts/Inter-Bold.woff') format('woff'),
         url('../fonts/Inter-Bold.ttf')  format('truetype'),
         url('../fonts/Inter-Bold.svg#Inter') format('svg');
  }
/*Reset*/
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,body{
    font-size: 18px;
}
body{
    font-family: "Inter-Regular",Arial, Helvetica, sans-serif;
    color:#727274;
}
a{
    text-decoration: none;
    transition: all .3s ease-in;
    color: #0385c7;
}
a:hover{
    text-decoration: none;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
img, svg {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}
h1{
    font-size: 2.5rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.5rem;
}
h4{
    font-size: 1.25rem;
}
h1,h2,h3,h4,h5,h6{
    font-family: "Inter-Bold",Arial, Helvetica, sans-serif;
    color: #4e4e50;
}
p{
    line-height: 1.4;
    margin-bottom: 20px;
}
p:last-of-type{
    margin-bottom: 0;
}
strong{
    font-family: "Inter-Medium",Arial, Helvetica, sans-serif;
    font-weight: normal;
}
img {
    max-width: 100%;
}
/*Common*/
.text-center{
    text-align: center!important;
}
.container{
    max-width: 1230px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}
/*Button Key Frame*/
@keyframes gelatine {
    from, to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
    from, to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
  @-webkit-keyframes gelatine {
    from, to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
    from, to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }  
.button{
    background: #09d62b;
    font-size: 17px;
    min-width: 220px;
    line-height: 25px;
    padding: 10px 15px;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    font-weight: 100;
    text-align: center;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: all .3s ease-in;
    border: 1px solid transparent;
}
.button.border{
    border: 1px solid #09d62b!important;
    background: none;
    color: #a0a0a2;
}
.button.small{
    font-size: 14px;
    line-height: 22px;
    padding: 5px 15px;
    min-width: 100px;
}
.button.xsmall{
    font-size: 12px;
    line-height: 20px;
    padding: 0px 15px;
    min-width: 70px;
}
.button.delete{
    background: #ff6161;
}
.button.blue{
    background: #513eeb;
}
.button.yellow{
    background: #f7c457;
}
.button.black{
    background: #000000;
}
.button:hover{
    -webkit-animation: gelatine 0.5s 1;
    animation: gelatine 0.5s 1;
    color: #fff;
    background: #20bf34;
}
.button.border:hover{
    color: #a0a0a2;
    background: none;
}
.button-outer{
    display: flex;
    justify-content: space-between;
    margin: 50px 0 10px;
}
/*close Icon*/
/*cross button*/
.closeIcon,.popup-close,.delete-item {
    width: 40px;
    height: 40px;
    background: transparent;
    border: 4px solid #9fa2a9;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: inline-block;
    border: 0px solid white;
    overflow: hidden;
    -moz-box-shadow: 0px 0px 0px 0px #9fa2a9 inset;
    -webkit-box-shadow: 0px 0px 0px 0px #9fa2a9 inset;
    box-shadow: 0px 0px 0px 0px #9fa2a9 inset;
    -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}
.closeIcon:after,.popup-close:after,.delete-item::after {
    width: 24px;
    height: 4px;
    background-color: #9fa2a9;
    content: "";
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -2px;
    position: absolute;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}
.closeIcon::before,.popup-close:before,.delete-item::before {
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -2px;
    width: 24px;
    height: 4px;
    background-color: #9fa2a9;
    content: "";
    position: absolute;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}  
.closeIcon:hover,.popup-close:hover,.delete-item:hover {
    -moz-box-shadow: 0px 0px 0px 6px #9fa2a9 inset;
    -webkit-box-shadow: 0px 0px 0px 6px #ff6161 inset;
    box-shadow: 0px 0px 0px 6px #ff6161 inset;
}
.closeIcon:hover:before,.popup-close:hover:before,.delete-item:hover::before {
    background: #ff6161;
    -moz-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
    -moz-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}
.closeIcon:hover:after,.popup-close:hover:after,.delete-item:hover:after {
    background: #ff6161;
    -moz-transform: scale(0.7) rotate(-45deg);
    -ms-transform: scale(0.7) rotate(-45deg);
    -webkit-transform: scale(0.7) rotate(-45deg);
    transform: scale(0.7) rotate(-45deg);
    -moz-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}
main{
    min-height: 500px;
    position: relative;
    overflow: hidden;
}
.head{
    margin: 0 auto 50px;
}
.head p strong{
    font-family: 'Inter-Medium';
}
.heading{
    font-size: 48px;
    color: #4e4e50;
    margin-bottom: 20px;
    line-height: 1.1;
    font-family: 'Inter-Bold';
}
.error{
    /* position: absolute!important; */
    color: red!important;
    margin: 2px 0 0 0!important;
    font-size: 12px!important;
    clear: both!important;
    display: block;
}
.hide{
    display: none!important;
}
input[type="radio"].custom_radio{
    display: none;
}
input[type="radio"].custom_radio + label{
    position: relative;
    display: inline-block;
    padding-left: 35px;
    cursor: pointer;
    font-family: 'Inter-Light';
    font-size: 18px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 0;
}
input[type="radio"].custom_radio + label:before,
input[type="radio"].custom_radio + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    color: white;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
input[type="radio"].custom_radio + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    /* box-shadow: inset 0 0 0 0.2em #e3e2e2, inset 0 0 0 1em #e3e2e2; */
    border: 1px solid #e6e6e6;
}
input[type="radio"].custom_radio + label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 6px #fff, inset 0 0 0 25px #39b54a;
}
input[type="radio"].custom_radio:checked + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 6px #fff, inset 0 0 0 25px #39b54a;
}
.green{
    color: #1ac12d!important;
}
.text-right{
    text-align: right!important;
}
.flex-heading{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 36px;
}
.flex-heading figure{
    margin: -2px 15px 0 0;
}
label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
}
.error-star {
    color: red!important;
    padding: 0!important;
    margin: 0 2px 0 0!important;
}
.green-link {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: #09d62b;
}
.green-link:hover {
    text-decoration: underline;
}
.form-control{
    padding-left: 10px;
    font-size: 15px;
}
.form-control:focus {
    border-color: #09d62b;
    box-shadow: none;
}
textarea.form-control{
    resize: none;
    height: 100px;
}
.red-circle{
    width: 10px; 
    height: 10px;
    border-radius: 50%;
    background: #ff6161;
    display: inline-block;
    margin-right: 6px;
}
.green-circle{
    width: 10px; 
    height: 10px;
    border-radius: 50%;
    background: #09d62b;
    display: inline-block;
    margin-right: 6px;
}
.mob-hide{
    display: inline-block!important;
}
.mob-show{
    display: none!important;
}
.fontSize24{
    font-size: 24px!important;
}
@media (max-width: 767px) {
    html, body {
        font-size: 16px;
    }
    .mob-hide{
        display: none!important;
    }
    .mob-show{
        display: inline-block!important;
    }
    .head{
        margin: 0 auto 30px;
    }
    .heading {
        font-size: 26px;
        margin-bottom: 10px;
    }
    .flex-heading{
        font-size: 24px;
    }
    p {
        margin-bottom: 15px;
    }
    .button-outer{
        flex-direction: column;
    }
    .button-outer .button{
        margin: 5px 0;
    }
    .button{
        font-size: 14px;
        min-width: 120px;
        line-height: 25px;
        padding: 5px 15px;
    }
    .button.small{
        padding: 2px 15px;
        min-width: 80px;
    }
    .green-link {
        font-size: 13px;
    }
    .fontSize24{
        font-size: 18px!important;
    }
}
/*Header*/
header{
    background: #fff;
    position: sticky;
    width: 100%;
    z-index: 99;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 58px;
    border-bottom: 1px solid #e4e3e8;
}
.header-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-right{
    display: flex;
    align-items: center;
}
header nav {
    display: flex;
    align-items: center;
}
.login-cart{
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.login-cart .login{
    border-radius: 5px;
}
.login-cart .cart-icon{
    position: relative;
    background: url('../images/header-cart.svg') no-repeat center center;
    background-size: 100%;
    width: 32px;
    height: 31px;
    margin-right: 6px;
}
.login-cart .cart-val{
    position: absolute;
    right: -3px;
    top: -9px;
    text-align: center;
    border-radius: 12px;
    min-width: 16px;
    height: 16px;
    padding: 0 2px;
    background-color: #ff6161;
    font-weight: 400;
    color: #f0f0f0;
    line-height: 18px;
    font-size: 12px;
}
.menu{
    display: flex;
    align-items: center;
}
.menu li{
    display: inline-block;
    position: relative;
    margin: 0 15px;
}
.menu li a{
    color: #a0a0a2;
    font-size: 14px;
    padding: 20px 0;
    display: block;
}
.menu li:last-child a{
    margin: 0;
}
.menu li a:hover,.menu li a.active{
    text-decoration: none;
    color: #4e4e50;
}
.menu-icon,.mob-nav-overlay {
    display: none; 
}
.sub-menu{
    min-width: 144px;
    right: -2px;
    left: auto;
    position: absolute;
    border-top: 2px solid #4e4e50;
    overflow-x: hidden;
    overflow-y: auto;
    top: 100%;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border-radius: 2px;
    padding: 5px 0 0 0;
    margin: 0;
    display: none;
  }
  .sub-menu li{
    margin: 0;
    display: block;
  }
  .sub-menu li a{
    font-size: 14px;
    line-height: 30px;
    padding: 0 16px;
    margin: 0 0 10px 0;
    white-space: nowrap;
    border: none!important;
    display: block;
    color: #a0a0a2;
  }
  .sub-menu li a:hover{
      color: #4e4e50;
  }
  .login-cart .user-profile {
    position: relative;
    padding-right: 14px;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 58px;
}
.login-cart .user-profile:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    right: 2px;
    top: 50%;
    margin-top: -4px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
    transition: all .5s ease-in-out;
}
.login-cart .user-profile .user-img {
    width: 31px;
    height: 31px;
    margin: 0 6px 0 0;
    border: 0px;
    background: #39b54a;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 12px;
    line-height: 1;
    cursor: pointer;
    display: none;
}
.login-cart .user-profile .user-img img{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
}
.login-cart .user-profile p {
    font-size: 14px;
    color: #a0a0a2;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 95px;
    transition: all .5s ease-in-out;
}
.login-cart .user-profile:hover p{
    color: #4e4e50;
}
.login-cart .user-profile .sub-menu .button {
    width: calc(100% - 20px);
    margin: 0 10px 10px;
}
.login-cart .user-profile:hover .sub-menu{
    display: block;
  }
@media (max-width: 767px) {
    header {
        padding: 10px 0;
        min-height: 50px;
    }
    header nav {
        background: #fff;
        position: fixed;
        top: 0;
        height: 100%;
        width: 280px;
        right: -40px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        padding: 50px 0;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        transform: translateX(100%);
        transition: all .4s;
        z-index: 99;
    }
    nav::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        border-bottom: 2px solid #edeeef;
    }
    .mob-menu-open{
        overflow: hidden;
    }
    .mob-menu-open nav{
        right: 280px;
    }
    .mob-nav-overlay {
        display: block;
        cursor: pointer;
        width: calc(100% - 280px);
        height: 100%;
        position: fixed;
        left: calc(100% + 280px);
        top: 0;
        z-index: 1;
        background: rgba(0,0,0,.5);
        transition: all .4s;
    }
    .mob-menu-open .mob-nav-overlay{
        visibility: visible;
        opacity: 1;
        left: 0;
    }
    .menu-icon {
        display: block;
        color: #000;
        position: absolute;
        right: 13px;
        font-weight: bold;
        line-height: 1;
        top: 9px; 
        z-index: 101;
    } 
    .menu-icon .bar1, .menu-icon .bar2, .menu-icon .bar3 {
        width: 28px;
        height: 3px;
        background-color: #000;
        margin: 6px 0;
        transition: 0.4s;
        display: block; 
    }
    .menu-icon.active .bar1 {
        -webkit-transform: rotate(-45deg) translate(-5px, 4px);
        transform: rotate(-45deg) translate(-5px, 4px); 
    }
    .menu-icon.active .bar2 {
        opacity: 0; 
    }
    .menu-icon.active .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px); 
    }
    .menu{
        padding-top: 10px;
        flex-direction: column;
        width: 100%;
    }
    .menu li{
        width: 100%;
        margin: 0;
    }
    .menu li a{
        display: block;
        margin: 0;
        padding: 10px 10px 10px 20px;
        font-size: 18px;
        font-weight: 500;
    }
    .login-cart{
        margin: 0 35px 0 0px;
    }
    .login-cart .cart-icon {
        width: 26px;
        height: 25px;
    }
    .login-cart .user-profile  .user-img {
        width: 26px;
        height: 26px;
        margin: 0 4px 0 0;
    }
    .login-cart .user-profile p {
        font-size: 12px;
        max-width: 65px;
    }
    .login-cart .user-profile{
        min-height: 25px;
        margin-top: 3px;
    }
}

/*Footer*/
footer{
    background: #f2f2f2;
    padding: 45px 0px;
}
.footer-top-inner{
    display: flex;
    flex-wrap: wrap;
}
.footer-top-inner h4{
    font-family: 'Inter-Medium';
    font-weight: normal;
    margin-bottom: 20px;
    font-size: 18px;
}
.footer-top-left,.footer-top-mid,.footer-top-right{
    width: 33.33%;
    font-family: 'Inter-Light';
}
.footer-top-left p,.footer-top-mid p,.footer-top-right p{
    font-family: 'Inter-Light';
}
.footer-logo{
    margin-bottom: 15px;
}
.footer-top-mid{
    text-align: center;
}
.footer-menu li a{
    color: #727274;
    font-family: 'Inter-Light';
    margin: 2px 0 0 0;
    display: inline-block;
    font-size: 15px;
}
.footer-menu li a:hover{
    color: #4e4e50;
    text-decoration: underline;
}
.footer-social{
    max-width: 135px;
    margin: 0 0 0 auto;
}
.footer-social h4{
    background: url('../images/soacal-bg.svg') no-repeat left center;
    padding-left: 14px;
}
.social-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.social-menu li{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 10px;
}
.social-menu li a{
    background: #727274;
    border-radius: 50%;
    overflow: hidden;
    width: 38px;
    height: 38px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-menu li a:hover{
    background: #09d62b;
}
.social-menu li a img{
    max-height: 19px;
    max-width: 16px;
}
.footer-bottom{
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #cfcfd3;
}
.footer-bottom p{
    text-align: center;
    font-family: 'Inter-Light';
    font-size: 15px;
}
.footer-bottom .footer-bootom-menu{
    display: flex;
    justify-content: space-around;
    max-width: 600px;
    margin: 0 auto 30px;
}
.footer-bottom .footer-bootom-menu li a{
    color: #727274;
    font-family: 'Inter-Light';
    font-size: 15px;
}
.footer-bottom .footer-bootom-menu li a:hover{
    color: #4e4e50;
    text-decoration: underline;
}
@media (max-width: 767px) {
    footer {
        padding: 25px 0px;
    }
    .footer-bottom .footer-bootom-menu li a {
        font-size: 14px;
    }
    .footer-top-left{
        width: 100%;
        margin: 0 0 20px 0;
        text-align: center;
    }
   .footer-top-mid, .footer-top-right{
        width: 50%;
    }
}
/*Banner*/
.banner{
    padding: 55px 0;
    background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(249,248,253,1) 100%);
}
.banner-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.banner-left{
    width: 100%;
    max-width: 370px;
}
.banner-left h1{
    font-size: 48px;
    padding-bottom: 35px;
    line-height: 1;
}
.banner-left p{
    font-family: 'Inter-Light';
    padding-bottom: 35px;
}
.banner-left .microsoft{
    display: flex;
    align-items: center;
}
.banner-left .microsoft span{
    font-size: 14px;
    display: block;
    margin-top: 75px;
}
.banner-left .microsoft span img{
    margin-left: 5px;
}
.banner-right{
    width: 100%;
    max-width: 480px;
    position: relative;
}
.banner-right .banner-r-text{
    width: 140px;
    margin: 0 auto;
    position: absolute;
    bottom: 6px;
    left: 2px;
    right: 0;
}
.banner-right .banner-r-text h2{
    font-size: 24px;
}
.banner-right .banner-r-text h2 span{
    font-size: 17px;
    font-weight: 100;
    font-family: "AvenirLTPro-Light",Arial, Helvetica, sans-serif;
    color: #76797c;
    display: block;
}
.banner-right .banner-r-text h2 small{
    font-size: 13px;
    font-family: "AvenirLTPro-Light",Arial, Helvetica, sans-serif;
    color: #76797c;
    display: block;
    font-weight: 100;
    line-height: 1.4;
}
.banner-right .button.quiz{
    position: absolute;
    left: 173px;
    min-width: 132px;
    font-size: 13px;
    line-height: 1.2;
    bottom: 23px;
    padding: 10px;
}
@media (max-width: 991px) {
    .banner {
        padding: 25px 0;
    }
    .banner-inner{
        flex-direction: column;
        align-items: flex-start;
    }
    .banner-left{
        max-width: 100%;
        text-align: center;
    }
    .banner-left h1 {
        font-size: 35px;
        padding-bottom: 20px;
    }
    .banner-left p {
        padding-bottom: 15px;
    }
    .banner-left .microsoft {
        justify-content: center;
    }
    .banner-left .microsoft span{
        margin-top: 15px;
    }
    .banner-right{
        margin: 20px auto 0;
    }
}
@media (max-width: 479px) {
    .banner-right {
        width: 100%;
        max-width: 320px;
        position: relative;
        margin: 20px auto 0;
    }
    .banner-right .banner-r-text {
        position: relative;
        bottom: 6px;
        left: 22px;
        margin-top: -74px;
    }
    .banner-right .button.quiz {
        position: static;
        margin-top: 5px;
    }
}
/**/
.culture-outer{
    padding: 60px 0;
    /* background: #f9f8fd; */
}
.culture-inner{
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.multi-color-heading{
    font-size: 31px;
    max-width: 770px;
}
.multi-color-heading .blue{
    color: #9a7bff; 
}
.multi-color-heading .green{
    color: #1ac12d; 
}
.multi-color-heading .orange{
    color: #fe9a0b; 
}
.multi-color-heading .sky{
    color: #54b9f4; 
}
.culture-list{
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.culture-list li{
    text-align: center;
    margin: 60px 0 0 0;
    width: 45%;
}
.culture-list .img{
    margin-bottom: 25px;
}
.culture-list .img img{
    max-width: 176px;
}
.culture-list .culture-list-text{
    text-align: left;
}
.culture-list .culture-list-text h5{
    font-family: 'Inter-Medium';
    font-weight: normal;
    font-size: 24px;
    padding-bottom: 10px;
}
@media (max-width: 767px) {
    .culture-outer {
        padding: 30px 0;
    }
    .culture-inner{
        flex-direction: column;
    }
    .multi-color-heading{
        font-size: 22px;
    }
    .culture-list{
        flex-direction: column;
        margin: 0 0 20px 0;
    }
    .culture-list li{
        width: 100%;
        margin: 20px 0 0 0;
    }
}
/*video-testimonial*/
.video-testimonial{
    margin: 210px 0 0 0;
    padding: 0 0 60px 0;
    background: #ffe91c;
    position: relative;
    z-index: 1;
}
.video-testimonial:before{
    background: url(../images/video-testimonial.png) no-repeat center top;
    background-size: 100%;
    content: '';
    position: absolute;
    top: -210px;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
}
.video-testimonial .head{
    max-width: 800px;
}
.video-testimonial .head .heading{
    color: #2f2f30;
}
.video-testimonial .head p{
    color: #4e4e50;
}
.video-sec {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-sec::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-sec iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.video-sec{
    margin: 0 auto 40px auto;
    text-align: center;
}
.video-sec img{
    border-radius: 25px;
}
.testimonial-sec{
    padding: 40px 0 0 0;
}
.testimonial-inner{
    width: calc(100% + 40px);
    margin-left: 40px;
}
.testimonial-inner.right{
    margin-left: -80px;
}
.testimonial-box-m{
    padding: 8px;
}
.testimonial-box{
    display: flex!important;
    background: #f4f5f7;
    padding: 25px;
    border-radius: 15px;
}
.testimonial-box .img{
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.testimonial-box .testimonial-text{
    flex: 1;
}
.testimonial-box .testimonial-text p{
    font-family: 'Inter-Light';
    font-size: 18px;
    padding-bottom: 15px;
}
.testimonial-box .testimonial-text strong{
    font-family: 'Inter-Regular';
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #4e4e50;
    font-weight: normal;
    line-height: 1.4;
}
.testimonial-box .testimonial-text span{
    display: block;
    font-size: 14px;
    color: #4e4e50;
    padding-bottom: 10px;
}
@media (max-width: 1199px) {
    .testimonial-inner{
        width: 100%!important;
        margin: 0!important;
    }
}
@media (max-width: 767px) {
    .video-testimonial {
        padding: 30px 0 0 0;
        margin: 70px 0 0 0;
    }
    .video-testimonial:before {
        top: -60px;
    }
    .video-sec {
        margin: 0 0 20px 0;
    }
    .testimonial-sec {
        padding: 20px 0;
    }
    .testimonial-inner{
        flex-direction: column;
    }
    .testimonial-box {
        padding: 15px;
        width: 100%;
    }
}
/*left right sec*/
.subscriber-outer{
    padding: 60px 0;
}
.subscriber-inner{
    text-align: center;
    /* border: 1px solid #616163; */
    padding: 50px;
    border-radius: 25px;
}
.subscriber-inner,
.deeply-box,
.ins-sec{
    /* box-shadow: rgb(255 255 255 / 10%) 0px 1px 1px 0px inset, rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.subscriber-inner h3{
    font-size: 31px;
    margin-bottom: 30px;
}
.subscriber-inner form{
    max-width: 590px;
    margin: 0 auto;
}
.input-outer{
    position: relative;
}
.input-outer .subscribe-input{
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dddde0;
    border-radius: 7px;
    padding: 10px 100px 10px 10px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
}
.input-outer .submit{
    height: 42px;
    position: absolute;
    top: 0;
    right: 0;
    background: #09d62b;
    border: none;
    width: 90px;
    padding: 2px 5px;
    color: #fff;
    border-radius: 0px 7px 7px 0;
    transition: all .5s ease-in;
    cursor: pointer;
    font-size: 18px;
    font-weight: 100;
}
.input-outer .submit:hover{
    background: #319d40;
}
.input-outer .info{
    padding: 30px 0 0;
}
@media (max-width: 767px) {
    .subscriber-outer {
        padding: 30px 0;
    }
    .subscriber-inner{
        padding: 20px;
    }
    .subscriber-inner h3 {
        font-size: 22px;
        margin-bottom: 15px;
    }
}
/*branding sec*/
.branding-outer{
    padding: 60px 0;
    text-align: center;
}
.branding-outer img{
    width: 100%;
}
@media (max-width: 767px) {
    .branding-outer{
        padding: 20px 0;
        text-align: center;
    }
}
/*left right sec*/
.left-right-sec{
    padding: 60px 0;
}
.left-right-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
}
.left-right-inner:nth-child(even) .l-r-img{
    order: 1;
}
.l-r-img{
    width: 48%;
}
.l-r-text{
    width: 48%;
}
.l-r-text h4{
    font-size: 24px;
    font-family: 'Inter-Medium';
}
.l-r-text p{
    font-size: 22px;
}
@media (max-width: 767px) {
    .left-right-sec{
        padding: 20px 0;
    }
    .left-right-inner{
        flex-direction: column;
    }
    .left-right-inner:nth-child(even) .l-r-img{
        order: -1;
    }
    .l-r-img{
        width: 100%;
        margin: 10px 0;
    }
    .l-r-text{
        width: 100%;
        margin: 10px 0;
    }
    .l-r-text p{
        font-size: 18px;
    }
}
/*collection sec*/
.collection-list{
    padding: 60px 0;
}
.collection-list li{
    background: #ffe6f6;
    padding: 50px 12% 0;
    border-radius: 15px;
    text-align: center;
    margin: 0 0 50px;
}
.collection-list li:nth-child(3n+2){
    background: #d9fdff;
}
.collection-list li:nth-child(3n+3){
    background: #d9ffee;
}
.collection-list li h3{
    color: #2f2f30;
}
.collection-list li p{
    padding: 5px 0 25px;
    color: #4e4e50;
}
.collection-list li .button{
    margin: 25px 0;
}
.collection-list li .collection-img{
    margin-top: 30px;
}
@media (max-width: 767px) {
    .collection-list {
        padding: 30px 0;
    }
    .collection-list li {
        padding: 20px 20px 0;
        margin: 0 0 20px
    }
    .collection-list li p {
        padding: 5px 0;
    }
    .collection-list li .button {
        margin: 15px 0 0;
    }
}

/*Product page*/
.product-top-box{
    max-width: 900px;
    margin: 0 auto;
    padding: 70px 0;
    text-align: center;
}
.product-top-box h3{
    font-size: 47px;
}
.product-top-box p{
    padding: 25px 0;
}
.product-top-box .button{
    margin: 25px 0;
}
@media (max-width: 767px) {
    .product-top-box {
        padding: 20px 0 0;
        margin: 0 0 20px
    }
    .product-top-box h3 {
        font-size: 30px;
    }
    .product-top-box p {
        padding: 5px 0;
    }
    .product-top-box .button {
        margin: 15px 0;
    }
}
.product-list{
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-top: 60px;   
}
.product-list li{
    width: calc(33.33% - 30px);
    margin: 3px 15px;
    background: #fbfbfb;
    padding: 40px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
/* .product-list li:nth-child(3n+2){
    margin-top: -30px;
    margin-bottom: 36px;
}
.product-list li:nth-child(3n+3){
    margin-top: -55px;
    margin-bottom: 61px;
} */
.product-list li .img{
    position: relative;
    z-index: 1;
}
/* .product-list li .img::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: -8px;
    border-radius: 0 0 0 5px;
    background: #abbbbe;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.product-list li .img:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 14px;
    top: 1px;
    left: -5px;
    background: #abbbbe;
    z-index: 1;
    transform: rotate(49deg);
    border-radius: 3px;
    z-index: -1;
}
.product-list li .img .right-corner {
    position: absolute;
    display: inline-block;
    content: "";
    position: absolute;
    width: 11px;
    background: #abbbbe;
    z-index: -1;
    height: 11px;
    bottom: -8px;
    right: 2px;
    transform: skew(-21deg);
    border-radius: 0;
} */
.product-hover{
    width: 100%;
    height: 100%;
    background: #fbfbfb;
    padding: 40px 40px 100px 40px;
    position: absolute;
    left: 0;
    bottom: -100%;
    z-index: 9;
    transition: all .5s ease-in-out;
    visibility: hidden;
    cursor: initial;
}
.product-hover h4{
    font-size: 25px;
    padding-bottom: 20px;
}
.product-hover p{
    font-family: 'Inter-Light';
}
.product-hover .button{
    position: absolute;
    bottom: 40px;
    min-width: calc(100% - 80px);
}
.product-list li:hover .product-hover{
    bottom: 0;
    visibility: visible;
}
@media (max-width: 767px) {
    .product-list {
        padding: 30px 0;
        margin: 0 -5px!important;
    }
    .product-list li{
        width: calc(50% - 10px);
        padding: 10px;
        margin: 3px 5px!important;
    }
    .product-hover{
        padding: 10px;
        overflow-y: auto;
    }
    .product-hover h4 {
        font-size: 18px;
        padding-bottom: 5px;
    }
    .product-hover p{
        font-size: 14px;
    }
    .product-hover .button{
        position: static;
        width: 100%;
        padding: 5px;
        line-height: 20px;
        font-size: 14px;
        margin-top: 5px;
    }
}
/*product detail*/
.product-d-top{
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-d-left{
    display: flex;
    flex-wrap: wrap;
    width: 48%;
}
.product-detail-list{
    padding: 50px 0;
}
.product-detail-list li{
    margin: 0 0 15px 0;
}
.product-detail-list li .img img{
    border-radius: 10px;
}
.product-d-right{
    width: 44%;
    text-align: center;
}
.product-d-right .amount{
    display: block;
    font-size: 31px;
    margin: 25px 0;
    position: relative;
    color: #4e4e50;
    font-family: "Inter-Bold",Arial, Helvetica, sans-serif;
}
.product-d-right .amount sup{
    /* position: absolute; */
    margin: 2px 0 0 -13px;
    font-size: 20px;
}
.cart-numb{
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    text-align: center;
}
.cart-numb li{
    width: 55px;
    height: 55px;
    background: #666666;
    border-radius: 50%;
    font-size: 22px;
    color: #fff;
    line-height: 59px;
    margin: 0 8px;
    cursor: pointer;
}
.cart-numb li.active{
    background: #09d62b;
}
.choose-n{
    font-size: 14px;
    margin-bottom: 30px;
    display: block;
}
.congratulations{
    margin: 30px 0 0;
}
.congratulations p{
    font-size: 14px;
}
.congratulations p span{
    display: inline-block;
    background: #ffe91c;
    clear: both;
    padding: 2px 8px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.pro-list{
    margin-bottom: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pro-list li{
    text-align: center;
    margin: 60px 0 0 0;
    width: 45%;
}
.pro-list .img{
    margin-bottom: 25px;
}
.pro-list .img img{
    max-width: 176px;
}
.pro-list p{
    font-family: 'Inter-Light';
    text-align: left;
}
.ins-sec{   
    display: flex;
    align-items: center;
    padding: 45px 80px;
    border-radius: 15px;
    /* border: 1px solid #d4d4d4; */
    justify-content: space-between;
    margin: 0 auto 20px;
    flex-wrap: wrap;
}
.ins-sec h3{
    width: 100%;
}
.ins-sec p{
    font-family: 'Inter-Light';
}
.ins-left{
    width: 58%;
}
.ins-left p.blue{
    color: #33adfb;
}
.ins-right{
    width: 26%;
}
.ins-right p{
    font-size: 16px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .product-d-top {
        padding: 30px 0;
        flex-direction: column;
    }
    .product-d-left{
        width: 100%;
    }
    .product-d-right {
        width: 100%;
        padding: 20px 0 0 0;
    }
    .product-d-right .button{
        width: 100%;
    }
    .congratulations {
        margin: 20px 0 0 0;
    }
    .pro-list{
        flex-direction: column;
        margin-bottom: 30px;
    }
    .pro-list li{
        width: 100%;
        margin: 20px 0 0 0;
    }
    .ins-sec{
        flex-direction: column;
        padding: 20px;
    }
    .ins-left{
        width: 100%;
    }
    .ins-right{
        width: 100%;
        margin-top: 20px;
    }
    .product-detail-list{
        padding: 20px 0;   
    }
}

/*PopUp Outer*/
.popup-outer{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    opacity: 0;
    transition: opacity .15s linear;
    visibility: hidden;
    z-index: -999;
}
.popup-outer.show{
    opacity: 1;
    visibility: visible;
    z-index: 999;
}
.popup-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    display: none;
}
.popup-outer.show .popup-overlay {
    display: block;
}
.popup-outer.show .popup-sec {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
}
.popup-close{
    position: absolute;
    right: 15px;
    top: 15px;
}
.popup-outer.small .popup-sec{
    max-width: 400px;
    padding: 50px 40px 40px;
}
.popup-sec{
    max-width: 920px;
    width: calc(100% - 30px);
    padding: 50px;
    background: #fff;
    margin: 1.75rem auto;
    position: relative;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-25%);
    transform: translate(0,-25%);
    box-shadow: 0 0 5px #4e4e50;
    border-radius: 15px;
}
.popup-sec h4{
    margin-bottom: 20px;
    font-size: 31px;
}
.popup-sec .qus-list{
    margin: 0 0 30px 0;
    padding: 0 0 0 5px;
}
.popup-sec #question .text-center{
    display: flex;
    justify-content: center;
    column-gap: 15px;
}
.popup-sec .qus-list li{
    padding: 5px 0;
}
.popup-sec .qus-list li input[type="radio"].custom_radio + label{
    font-size: 22px;
    line-height: 1.3;
}
.popup-bottom .steps{
    display: flex;
    overflow: hidden;
    margin: 40px auto 20px;
    max-width: 700px;
}
.maxWidth700{
    margin: 0 auto;
    max-width: 700px;
}
.popup-bottom .steps li{ 
    background: #e6e6e6;
    width: calc(10% - 6px);
    height: 13px;
    text-indent: -999px;
    overflow: hidden;
    margin: 0 3px;
}
.popup-bottom .steps li.yellow{
    background: #f7ce27;
 }
.popup-bottom .steps li.green{
    background: #39b54a;
 }
.popup-bottom .steps li:first-child{
    border-radius: 8px 0 0 8px;
}
.popup-bottom .steps li:last-child{
    border-radius: 0 8px 8px 0;
}
.steps-info{
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    font-family: 'Inter-Light';
}
.popup-sec .buttons{
    padding: 40px 0 0;
}
.popup-sec .buttons .take-button{
    text-decoration: underline;
    color: #b3b3b3;
    text-transform: uppercase;
    margin-top: 30px;
    display: inline-block;
    font-size: 14px;
}
.popup-sec .buttons .take-button:hover{
    text-decoration: none;
    color: #727274;
    
}
@media (max-width: 767px) {
    .popup-sec {
        padding: 60px 20px 20px 20px;
    }
    .popup-sec h4{
        margin-bottom: 10px;
        font-size: 22px;
    }
}

/*Cart popup*/
.table.border-none th,.table.border-none td{
    border-top: none;
}
.table{
    width: 100%;
    border-spacing: 0;
}
.table th,.table td{
    padding: 12px 5px 8px;
    text-align: left;
    border-top: 1px solid #eaeaea;
    color: #4e4e50;
    font-family: 'Inter-Regular';
}
.table td{
    color: #727274;
    position: relative;
    font-family: 'Inter-Light';
}
.table td.last-ch{
    padding: 25px 0 17px;
    font-family: 'Inter-Medium';
    color: #4e4e50;
}
.table td span{
    background: #ffe91c;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
}
.delete-item{
    position: absolute;
    transform: scale(.6);
    left: -30px;
    top: 6px;
    cursor: pointer;
}
@media (max-width: 767px) {
    .table th,.table td{
        font-size: 12px;
    }
    .delete-item {
        position: absolute;
        transform: scale(.4);
        left: -26px;
        top: 2px;
        cursor: pointer;
    }
}
.or-text {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 15px;
}
.or-text::before {
    content: '';
    position: absolute;
    background: #bbb;
    left: 0;
    top: 50%;
    z-index: -1;
    width: 100%;
    height: 1px;
}
.or-text strong {
    background: #fff;
    padding: 0 15px;
    display: inline-block;
    font-size: 30px;
}
.or-text.small strong{
    font-family: "Inter-Regular",Arial, Helvetica, sans-serif;
    font-size: 18px;
}
.btn-outer{
    text-align: center;
    margin-top: 20px;
}
.btn-outer .button{
    margin: 5px;
}
.already-signup span {
    margin-top: 20px;
    display: block;
    text-align: center;
}
.col-sm-12.text-right .green-link{
    font-size: 14px;
}
@media (max-width: 767px) {
    .or-text {
        margin-bottom: 15px;
    }
    .or-text strong {
        font-size: 20px;
    }
}
.manage-a-inner{
    padding: 50px 0;
    display: flex;
}
.tab-outer{
    min-width: 220px;
    margin-right: 50px;
}
.tab-outer .name-outer{
    padding: 10px 15px;
    border: 1px solid #e4e3e8;
    margin-bottom: 10px;
    background: #f2f3f6;
    box-shadow: 0 0 2px #ceced7;
}
.tab-outer .name-outer h6{
    margin: 0;
    font-size: 17px;
    color: #727274;
}
.tab-list{
    border: 1px solid #e4e3e8;
    background: #f2f3f6;
    box-shadow: 0 0 2px #ceced7;
}
.tab-list li{
    padding: 15px 20px 15px 15px;
    border-bottom: 2px solid #e4e3e8;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease-in;
}
.tab-list li:last-child{
    border-bottom: none;
}
.tab-list li:hover{
    background: #e4e3e8;
}
.tab-list figure{
    margin: 0 10px 0 0;
    min-width: 20px;
    text-align: center;
}
.tab-list figure img{
    max-height: 20px;
}
.address-sec{
    flex: 1;
}
.address-sec h3{
    font-size: 31px;
}
.manage-address > li{
    padding: 20px;
    padding-right: 120px;
    border: 1px solid #e4e3e8;
    position: relative;
    margin-bottom: 15px;
    min-height: 99px;
    box-shadow: 0 0 2px #ceced7;
}
.manage-address li:last-child{
    margin-bottom: 0;
}
.manage-address li strong{
    font-size: 16px;
    color: #4e4e50;
    margin-bottom: 10px;
    display: block;
}
.manage-address li strong span{
    margin-left: 15px;
}
.manage-address li p{
    font-size: 16px;
}
.edit-delete{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0;
    border-left: 1px solid #e4e3e8;
    box-shadow: 0 0 2px #ceced7;
}
.edit-delete li{
    padding: 10px;
    border: none;
    border-bottom: 2px solid #e4e3e8;
    margin: 0;
}
.edit-delete li:last-child{
    border-bottom: none;
}
.order-list li{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    flex-wrap: wrap;
    padding: 20px;
    border: 1px solid #e4e3e8;
    position: relative;
    margin-bottom: 15px;
    box-shadow: 0 0 2px #ceced7;
}
.order-list li strong{
    font-size: 15px;
    display: block;
}
.order-list li .ord-name{
    display: flex;
    align-items: center;
    width: 40%;
}
.order-list li .ord-name .img{
    min-width: 75px;
    max-width: 75px;
    margin-right: 30px;
}
.order-list li .ord-name .img img{
    max-width: 75px;
}
.order-list li .ord-name strong{
    font-family: 'Inter-Medium';
    font-size: 16px;
    flex: 1;
}
.order-list li .ord-frames{
    text-align: center;
    flex: 1;
}
.order-list li .ord-price{
    text-align: center;
    flex: 1;
}
.order-list li .ord-staus{
    text-align: right;
    flex: 1;
    min-width: 30%;
}
@media (max-width: 767px) {
    .manage-a-inner{
        flex-direction: column;
        padding: 30px 0;
    }
    .tab-outer{
        max-width: 100%;
        margin: 0 0 20px 0;
    }
    .address-sec h3 {
        font-size: 22px;
    }
    .manage-address li strong span {
        margin-left: 0;
        display: block;
    }
    .order-list li>div{
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid #e4e3e8;
        max-width: 100%!important;
        width: 100%!important;
        text-align: left!important;
    }
    .order-list li .ord-frames,.order-list li .ord-price {
        text-align: center;
        max-width: 50%!important;
        width: 50%!important;
    }
    .order-list li .ord-staus{
        order: -1;
        padding-top: 0;
        margin-top: 0;
        border-top: none;
    }
    .order-list li .ord-name strong {
        font-size: 14px;
    }
}
/*checkout page*/
.checkout-sec{
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
}
.checkout-sec .head {
    margin: 0 auto 20px;
}
.checkout-sec h3{
    font-size: 31px;
}
.checkout-left{
    width: calc(60% - 20px);
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #e4e3e8;
}
.checkout-right{
    width: 40%;
}
.checkout-left .shipping-info{
    padding-top: 20px;
}
.checkout-left .btn-outer{
    text-align: left;
}
.checkout-left .btn-outer .button{
    margin-left: 0;
}
.pay-btn-outer{
    margin: 20px -5px;
    display: flex;
    flex-wrap: wrap;
}
.pay-btn-outer .button{
    margin: 5px 5px;
    min-width: 200px; 
}
.pay-btn-outer a img{
    max-height: 25px;
}
.checkout-right .order-list li{
    padding: 10px;
}
.checkout-right .order-list li .ord-name {
    display: flex;
    align-items: center;
    width: 60%;
}
.checkout-right .order-list li .ord-name .img {
    margin-right: 15px;
}
.checkout-right .order-list li .ord-name strong {
    font-size: 14px;
}
.checkout-right .table.border-none td {
    font-size: 14px;
}
.subtotal{
    margin: 20px 0px;
}
.subtotal li{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #e4e3e8;
    font-family: 'Inter-Medium';
    flex-wrap: wrap;
}
.subtotal li .black{
    color: #000;
}
.contact-ship{
    padding: 15px;
    border: 1px solid #e4e3e8;
    box-shadow: 0 0 2px #ceced7;
}
.contact-ship li{
    border-bottom: 1px solid #e4e3e8;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: self-start;
    word-break: break-word;
}
.contact-ship li:last-child{
    padding: 0;
    margin: 0;
    border: none;
}
.contact-ship span {
    min-width: 100px;
    font-family: 'Inter-Medium';
    color: #000;
    display: inline-block;
}
.contact-ship li .button{
    margin: 2px 0 0 8px;
    white-space: nowrap;
}
.billing-address{
    padding-top: 30px;
}
.same-diff-ad{
    padding: 15px;
    border: 1px solid #e4e3e8;
    box-shadow: 0 0 2px #ceced7;
}
.same-diff-ad li{
    border-bottom: 1px solid #e4e3e8;
    padding-bottom: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: self-start;
    flex-wrap: wrap;
}
.same-diff-ad li:last-child{
    padding: 0;
    margin: 0;
    border: none;
}
.same-diff-ad label img{
    max-height: 28px;
}
.payment-sec{
    padding-top: 30px;
}
.cc-icon-outer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.cc-icon-outer input[type="radio"].custom_radio + label{
    flex: 1;
}
.cc-icon-outer .cc-icon{
    display: flex;
    align-items: center;
}
.cc-icon-outer .cc-icon img{
    margin-right: 10px;
}
.cc-info{
    margin-top: 10px;
    background: #e4e3e8;
    padding: 20px;
}
@media (max-width: 767px) {
    .checkout-sec{
        flex-direction: column;
        padding: 30px 0;
    }
    .checkout-sec h3{
        font-size: 22px;
    } 
    .checkout-left {
        width: 100%;
        padding: 0;
        border: none;
        margin: 0 0 20px 0;
    }
    .payment-list{
        text-align: center;
    }
    .pay-btn-outer {
        justify-content: center;
    }
    .pay-btn-outer .button {
        margin: 5px 5px;
        min-width: calc(50% - 10px);
    }
    .contact-ship span{
        min-width: 80px;
    }
    .cc-icon-outer .cc-icon{
        display: none;
    }
    .checkout-right {
        width: 100%;
    }
    .checkout-right .order-list li .ord-name {
        border: none;
    }  
    .checkout-right .table th,.checkout-right .table td {
        font-size: 18px;
    }
}
/*How it works*/
.deeply-text{
    padding: 30px 0;
}
.deeply-text p strong{
    font-size: 24px;
    line-height: 1.2;
    font-family: 'Inter-Medium';
    font-weight: normal;
}
.deeply-box{
    margin: 0 0 60px 0;
    padding: 45px 80px;
    border-radius: 15px;
    /* border: 1px solid #d4d4d4; */
}
@media (max-width: 767px) {
    .deeply-box {
        margin: 0 0 30px 0;
        padding: 20px;
    }
}
/*CEO Page*/
/* .ceo-sec{
    max-width: 790px;
    margin: 0 auto;
    border: 22px solid #727274;
    padding: 50px;
}
.ceo-sec .ceo-inner{
    min-height: 325px;
    max-width: 260px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
} */
.editor-text{
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
}
.editor-text h1{
    font-size: 48px;
    font-family: 'Inter-Bold';
    margin: 0;
}
.editor-text h2{
    font-size: 24px;
    font-family: 'Inter-Medium';
    margin: 0;
}
.editor-text h3{
    font-size: 24px;
    font-family: 'Inter-Medium';
    margin: 0;
}
.editor-text h4{
    font-size: 24px;
    font-family: 'Inter-Medium';
    margin: 0;
}
.editor-text h5{
    font-size: 18px;
    font-family: 'Inter-Regular';
    margin: 0;
}
.editor-text h6{
    font-size: 18px;
    font-family: 'Inter-Light';
    margin: 0;
}
.why-sec{
    padding: 80px 0 30px;
}
.list {
    padding: 0 0 0 20px;
}
.list li{
    list-style: disc;
}
@media (max-width: 767px) {
    /* .ceo-sec{
        border: 12px solid #727274;
        min-height: 130px;
        padding: 50px 10px;
    }
    .ceo-sec .ceo-inner{
        min-height: 130px;
    } */
    .why-sec{
        padding: 30px 0 10px;
    }
}

/*Loader*/
.loader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
    background-position: center center;
    background-repeat: no-repeat;
}
/*Contact form*/
.contact-inner{
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0;
    justify-content: space-between;
}
.contact-info{
    width: 48%;
}
.info-list{
    padding-top: 200px;
}
.info-list li{
    display: flex;
    padding: 20px 0;
    font-family: 'Inter-Light';
    line-height: 1.2;
}
.info-list figure{
    margin: 0 50px 0 0;
}
.info-list figure img{
    max-height: 20px;
}
.contact-form{
    width: 48%;
    border: 1px solid #616163;
    padding: 35px;
    border-radius: 25px;
}
.contact-form h3{
    font-size: 24px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .contact-inner{
        padding: 40px 0;
        flex-direction: column;
    }
    .contact-info{
        width: 100%;
        padding-bottom: 20px;
    }
    .info-list {
        padding-top: 0;
    }
    .contact-form{
        width: 100%;
        padding: 20px;
    }
    .info-list figure {
        margin: 0 15px 0 8px;
    }
}

/**/
.delevery-list li{
    justify-content: space-between;
}
.delevery-list li .edit{
    display: none;
    margin-left: 20px;
}
.delevery-list li.active .edit{
    display: block;
}
.delevery-list li label{
    flex: 1;
}
.delevery-list li label strong {
    font-size: 16px;
    color: #4e4e50;
    margin-bottom: 10px;
    display: block;
}
.delevery-list li p{
    font-size: 16px;
    font-family: "Inter-Regular",Arial, Helvetica, sans-serif;
}

/* added by AKM */
.disabled{
    background: #6c757d !important
}