@font-face {
  font-family: 'Inter Light';
  src: url('Inter-Light.eot');
  src: url('Inter-Light.eot?#iefix') format('embedded-opentype'),
       url('Inter-Light.woff2') format('woff2'),
       url('Inter-Light.woff') format('woff'),
       url('Inter-Light.ttf')  format('truetype'),
       url('Inter-Light.svg#Inter Light') format('svg');
}
@font-face {
  font-family: 'Inter Regular';
  src: url('Inter-Regular.eot');
  src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
       url('Inter-Regular.woff2') format('woff2'),
       url('Inter-Regular.woff') format('woff'),
       url('Inter-Regular.ttf')  format('truetype'),
       url('Inter-Regular.svg#Inter') format('svg');
}
@font-face {
  font-family: 'Inter Medium';
  src: url('Inter-Medium.eot');
  src: url('Inter-Medium.eot?#iefix') format('embedded-opentype'),
       url('Inter-Medium.woff2') format('woff2'),
       url('Inter-Medium.woff') format('woff'),
       url('Inter-Medium.ttf')  format('truetype'),
       url('Inter-Medium.svg#Inter Medium') format('svg');
}
@font-face {
  font-family: 'Inter Bold';
  src: url('Inter-Bold.eot');
  src: url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
       url('Inter-Bold.woff2') format('woff2'),
       url('Inter-Bold.woff') format('woff'),
       url('Inter-Bold.ttf')  format('truetype'),
       url('Inter-Bold.svg#Inter') format('svg');
}